import React from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import Image from "../elements/Image";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

class Clients extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "clients section reveal-fade",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "clients-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className="reveal-from-bottom" data-reveal-delay="300">
            Some Teams We've Invested In
          </div>
          <div className={innerClasses}>
            <ul className="list-reset">
              <li>
                <a href="https://www.position-imaging.com/">
                  <Image
                    src={require("./../../assets/images/companies/PI.svg")}
                    alt="Client 01"
                    width={124}
                    height={24}
                  />
                </a>
              </li>
              <li>
                <a href="https://insurify.com/">
                  <Image
                    src={require("./../../assets/images/companies/insurify.svg")}
                    alt="Client 02"
                    width={83}
                    height={30}
                  />
                </a>
              </li>
              <li>
                <a href="https://luminoso.com/">
                  <Image
                    src={require("./../../assets/images/companies/luminoso.svg")}
                    alt="Client 03"
                    width={125}
                    height={39}
                  />
                </a>
              </li>
              <li>
                <a href="https://www.xometry.com/">
                  <Image
                    src={require("./../../assets/images/companies/xometry.svg")}
                    alt="Client 04"
                    width={150}
                    height={31}
                  />
                </a>
              </li>
              <li>
                <a href="https://happy.co/">
                  <Image
                    src={require("./../../assets/images/companies/happyco.svg")}
                    alt="Client 05"
                    width={113}
                    height={30}
                  />
                </a>
              </li>
              <li>
                <a href="https://www.statsocial.com/">
                  <Image
                    src={require("./../../assets/images/companies/statsocial.svg")}
                    alt="Client 05"
                    width={113}
                    height={30}
                  />
                </a>
              </li>
            </ul>
            <ul className="list-reset">
              <li>
                <a href="https://www.rivermeadow.com/">
                  <Image
                    src={require("./../../assets/images/companies/rivermeadow.svg")}
                    alt="Client 05"
                    width={113}
                    height={30}
                  />
                </a>
              </li>
              <li>
                <a href="https://www.sparxhockey.com/">
                  <Image
                    src={require("./../../assets/images/companies/sparx.svg")}
                    alt="Client 05"
                    width={113}
                    height={30}
                  />
                </a>
              </li>
              <li className="reveal-from-top">
                <a href="http://www.aromyx.com/">
                  <Image
                    src={require("./../../assets/images/companies/aromyx.svg")}
                    alt="Client 05"
                    width={113}
                    height={30}
                  />
                </a>
              </li>
              <li>
                <a href="https://www.formulusblack.com/">
                  <Image
                    src={require("./../../assets/images/companies/formulusblack.svg")}
                    alt="Client 05"
                    width={113}
                    height={30}
                  />
                </a>
              </li>
              <li>
                <a href="https://bactobyte.com/">
                  <Image
                    src={require("./../../assets/images/companies/bactobyte.svg")}
                    alt="Client 05"
                    width={113}
                    height={30}
                  />
                </a>
              </li>
              <li className="reveal-from-top">
                <a href="https://www.shopadvisor.com/">
                  <Image
                    src={require("./../../assets/images/companies/shopadvisor.svg")}
                    alt="Client 05"
                    width={113}
                    height={30}
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    );
  }
}

Clients.propTypes = propTypes;
Clients.defaultProps = defaultProps;

export default Clients;
