import React from "react";
import classNames from "classnames";

const FooterSocial = ({ className, ...props }) => {
  const classes = classNames("footer-social", className);

  return (
    <div {...props} className={classes}>
      <ul className="list-reset">
        <li>
          <a href="https://www.linkedin.com/in/markrosenblatt/">
            <svg
              height="20"
              viewBox="-21 -35 682.66669 682"
              width="20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m77.613281-.667969c-46.929687 0-77.613281 30.816407-77.613281 71.320313 0 39.609375 29.769531 71.304687 75.8125 71.304687h.890625c47.847656 0 77.625-31.695312 77.625-71.304687-.894531-40.503906-29.777344-71.320313-76.714844-71.320313zm0 0" />
              <path d="m8.109375 198.3125h137.195313v412.757812h-137.195313zm0 0" />
              <path d="m482.054688 188.625c-74.011719 0-123.640626 69.546875-123.640626 69.546875v-59.859375h-137.199218v412.757812h137.191406v-230.5c0-12.339843.894531-24.660156 4.519531-33.484374 9.917969-24.640626 32.488281-50.167969 70.390625-50.167969 49.644532 0 69.5 37.851562 69.5 93.339843v220.8125h137.183594v-236.667968c0-126.78125-67.6875-185.777344-157.945312-185.777344zm0 0" />
            </svg>
          </a>
        </li>
        <li>
          <a href="https://angel.co/p/mark-rosenblatt">
            <svg
              enable-background="new 0 0 24 24"
              height="20"
              viewBox="0 0 24 24"
              width="20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m11.357 24c5.636 0 9.143-4.153 9.143-9.511 0-2.02-.329-3.844-2.622-4.392.558-1.528 2.168-5.948 2.168-7.364 0-1.247-.75-2.292-2.087-2.292-2.131 0-4.041 6.173-4.639 7.645-.463-1.336-2.631-8.086-4.542-8.086-1.485 0-2.182 1.073-2.182 2.423 0 1.655 1.633 5.944 2.225 7.594-.3-.108-.625-.202-.956-.202-1.118 0-2.307 1.364-2.307 2.466 0 .417.234 1.003.382 1.392-1.762.469-2.44 1.622-2.44 3.361-.005 3.384 3.263 6.966 7.857 6.966zm4.83-18.937c.192-.577 1.008-3.014 1.772-3.014.411 0 .521.417.521.75 0 .895-1.843 5.841-2.25 6.937l-1.624-.281zm-8.091-2.798v-.001c0-.558.693-2.142 2.211 2.208l1.653 4.702c-.745-.061-1.323-.141-1.69.066-.521-1.35-2.174-5.611-2.174-6.975zm-.11 9.172.001.001c1.399 0 3.205 4.434 3.205 5.034 0 .239-.234.534-.506.534-.999 0-3.674-3.605-3.674-4.58.004-.361.606-.989.974-.989zm-1.608 3.67c.544 0 2.593 2.827 2.593 3.427 0 .23-.368.389-.558.389-.769 0-1.07-.727-2.44-2.409-1.419 1.392.979 4.073 2.785 4.073 1.246 0 2.058-1.134 1.815-1.969.177 0 .396.014.559-.028.053 1.27.435 2.784 1.992 2.892 0-.042.096-.333.096-.347 0-.816-.506-1.528-.506-2.358 0-1.327 1.036-2.611 2.087-3.361.382-.281.846-.455 1.294-.614.464-.173.956-.375 1.308-.722-.053-.525-.272-.989-.807-.989-1.323 0-5.76.188-5.76-1.861 0-.314.005-.614.832-.614 1.543 0 5.46.375 6.606 1.364.864.755 1.161 5.306-1.481 8.189l-.003.001c-1.39 1.5-3.167 2.278-5.24 2.278-2.837 0-5.078-1.528-6.157-4.139-.817-2.034.182-3.202.985-3.202zm5.701-.844c.465.146.942.189 1.42.282-.354.253-.668.563-.97.895-.134-.398-.297-.787-.45-1.177z" />
            </svg>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default FooterSocial;
