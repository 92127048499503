import React from "react";
// import section header
import SectionHeader from "../components/sections/partials/SectionHeader";
// import sections
import HeroSplit from "../components/sections/HeroSplit";
import Clients from "../components/sections/Clients";
import GenericSection from "../components/sections/GenericSection";
import FeaturesTiles from "../components/sections/FeaturesTiles";
import Testimonial from "../components/sections/Testimonial";
import Cta from "../components/sections/Cta";
// import some required elements
import Image from "../components/elements/Image";
import Modal from "../components/elements/Modal";
import LargeQuote from "../components/sections/LargeQuote";

class Home extends React.Component {
  state = {
    videoModalActive: false,
  };
  openModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: true });
  };

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: false });
  };

  render() {
    const sectionHeader = {
      title: "bringing deep technology capability from early stage to market",
      paragraph:
        "Rationalwave works closely with its portfolio companies to help bring novel technologies to market. We've invested in cutting edge technology companies in their early stages and advised them all the way to IPO.",
    };
    const sectionHeaderAbout = {
      title: "we've been around since ringtones were cool",
      paragraph:
        "In fact, we made them cool. Rationalwave was founded in 2003 by Mark Rosenblatt after helping start and sell YourMobile.com, the first legal ringtone company in the US. Mark takes board, advisor, and sometimes operational roles in Rationalwave’s investments. More recently Mark also founded, ran, and sold Rationalwave Analytics, a machine learning company. Mark and lab partner, Joel Halbert, likely created the world’s first reconfigurable touch screen for his senior project at college long before the iPhone but did not pursue this further as they knew nothing about Silicon Valley or venture capital.  He now makes sure his companies take their cool tech to market.",
    };

    return (
      <React.Fragment>
        <HeroSplit className="illustration-section-01" />
        <Clients topDivider bottomDivider />
        <GenericSection topDivider className="center-content">
          <SectionHeader
            data={sectionHeader}
            className="reveal-from-bottom"
          ></SectionHeader>
        </GenericSection>
        <LargeQuote className="illustration-section-02" />
        <GenericSection topDivider className="center-content">
          <SectionHeader
            data={sectionHeaderAbout}
            className="reveal-from-bottom"
          ></SectionHeader>
        </GenericSection>
        <Cta split />
      </React.Fragment>
    );
  }
}

export default Home;
